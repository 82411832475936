<template>
  <div 
    class="profile-picture" 
    :class="borderRadiusClass"
    :style="style"
  />
</template>

<script>
export default {
  props: {
    widthAndHeightInPixels: { type: Number, required: true },
    backgroundImageUrl: { type: String, default: null },
    rounded: { type: Boolean, default: false },
  },

  computed: {
    style() {
      return {
        '--picture-size': `${this.widthAndHeightInPixels}px`,
        'backgroundImage': this.getBackgroundImageUrl(
          this.backgroundImageUrl
        )
      }
    },
    borderRadiusClass() {
      return this.rounded
        ? 'rounded-circle'
        : 'border-radius-lg'
    },
  },

  methods: {
    getBackgroundImageUrl: (url) => url != null && url !== ""
      ? `url(${url})`
      : 'url(/assets/img/placeholder_avatar.png)',
  }
}
</script>

<style scoped>
.profile-picture {
  width: var(--picture-size);
  min-width: var(--picture-size);
  height: var(--picture-size);
  background-size: cover;
  background-position: center;
  background-color: #fff;
}
</style>