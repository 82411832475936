<template>
  <div id="profile" class="card card-body">
    <div class="row align-items-center">
      <div class="col-sm-auto col-4">
        <div class="avatar avatar-xl shadow-sm rounded-circle overflow-hidden">
          <div
            v-if="profilePicture || profilePictureData"
            class="position-relative"
          >
            <img
              :alt="`Profielfoto ${fullName}`"
              class="user-profile-picture-existing__image"
              :src="profilePicture || profilePictureData"
            />

            <button
              class="user-profile-picture-existing-remove position-absolute top-0 start-0 bottom-0
                     p-0 border-0 w-100 bg-transparent"
              @click="removeProfilePicture"
            >
              <div
                class="user-profile-picture-existing-remove__background w-100 h-100 bg-danger"
              />

              <i
                class="position-absolute top-0 start-0 bottom-0 end-0 d-flex justify-content-center
                       align-items-center material-icons text-light fs-2"
              >
                delete_outline
              </i>
            </button>
          </div>

          <label
            v-else
            class="d-flex justify-content-center align-items-center w-100 h-100 m-0 bg-gradient-dark cursor-pointer"
            for="profilePictureData"
          >
            <i class="d-block material-icons text-secondary fs-2">
              add_a_photo
            </i>
            
            <input
              id="profilePictureData"
              accept="image/*"
              capture="user"
              class="d-none"
              type="file"
              @change="profilePictureChanged"
            />
          </label>
        </div>
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">
            {{ fullName }}
          </h5>

          <p class="mb-0 font-weight-normal text-sm">
            {{ companyName }}
            <template v-if="companyName && profession">
              //
            </template>
            {{ profession }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserProfile',

  props: {
    companyName: {
      type: String,
      required: true,
    },
    fullName: {
      type: String,
      required: true,
    },
    profession: {
      type: String,
      required: true,
    },
    profilePicture: {
      type: String,
      required: true,
    },
    profilePictureData: {
      type: String,
      required: true,
    },
  },

  emits: [
    'update:profilePicture',
    'update:profilePictureData',
  ],

  methods: {
    profilePictureChanged({ target }) {
      const reader = new FileReader();
      reader.onload = ({ target }) => {
        this.$emit('update:profilePictureData', target.result);
      };
      reader.readAsDataURL(target.files[0]);
    },

    removeProfilePicture() {
      if (!confirm('Weet je zeker dat je de profielfoto wil verwijderen?')) {
        return;
      }

      this.$emit('update:profilePicture', '');
      this.$emit('update:profilePictureData', '');
    },
  },
};
</script>

<style scoped lang="scss">
.user-profile {

  &-picture {

    &-existing {

      &__image {
        aspect-ratio: 1;
        object-fit: cover;
      }

      &-remove {
        transition: opacity .2s;

        &:not(:hover) {
          opacity: 0;
        }

        &__background {
          opacity: .75;
        }
      }
    }
  }
}
</style>
