import { remove as apiRemove } from '@/api/providers/users';
import store from '@/store';

import { createYesNoSweetAlert } from './sweetalert';

export function parseFullName(firstName, insertion, lastName) {
  return [
    firstName,
    insertion,
    lastName,
  ]
    .filter((namePart) => namePart)
    .join(' ');
}

export function remove(id, name) {
  return new Promise((resolve, reject) => {
    createYesNoSweetAlert(
      `App-gebruiker ${name} verwijderen?`,
      () => { 
        apiRemove(id)
          .then(() => {
            store.dispatch('notification/add', {
              description: `${name} is verwijderd.`,
              icon: {
                component: 'person_remove',
              },
            });
            resolve();
          })
        .catch((error) => reject(error));
      },
      () => reject()
    )
  });
}
