import { deleteRequest, getRequest, postRequest, putRequest } from '..';

export function add(data) {
  return postRequest('/company', data);
}

export function edit(companyId, data) {
  return putRequest(`/company/${companyId}`, data)
}

export function get(companyId) {
  return getRequest(`/company/${companyId}`);
}

export function getAll() {
  return getRequest('/company');
}

export function remove(companyId) {
  return deleteRequest(`/company/${companyId}`);
}

export function addUser(companyId, userId, canEdit, userFunction) {
  return postRequest('/company/user', { companyId, userId, canEdit, userFunction });
}

export function updateUser(companyId, userId, canEdit, userFunction) {
  return putRequest('/company/user', { companyId, userId, canEdit, userFunction });
}

export function removeUser(companyId, userId) {
  return deleteRequest(`/company/${companyId}/user/${userId}`);
}